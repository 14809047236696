import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";
import SubscribeEmail from "../components/newsletter/SubscribeEmail";

const ComingSoon02 = () => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&family=Roboto:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div
        className="cs-02-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/backgrounds/coming-soon-02.jpg"
          })`
        }}
      >
        {/*====================  header ====================*/}
        <header className="cs-02-header space-pt--30 space-pb--30">
         
        </header>
        {/*====================  End of header  ====================*/}
        {/*====================  content ====================*/}
        <br></br>
        <div className="cs-02-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <h2 className="cs-02-content__title">
                  The Culture Of<br />Cali Streetcar Takeovers<br />And Puffing
                </h2>
                <div className="cs-02-subscription-wrapper space-mt--50">
                  {/* subscribe email */}
                  <SubscribeEmail mailchimpUrl="https://hellcatdrsop.us12.list-manage.com/subscribe/post?u=4206390fd01515550e69b28ef&amp;id=6f3143cb85&amp;f_id=003886e0f0"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-02-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                {/* social icons */}
                <ul className="cs-02-social-icons d-flex align-items-center justify-content-center justify-content-md-start">
                  <li>
                    <a
                      href="http://www.x.com/hellcatdrops"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/x.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.instagram.com/hellcatdrops"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/instagram-02.svg"
                        }
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                {/* copyright */}
                <div className="cs-02-copyright text-right">
                  
                  <a
                    href="https://24k.studio/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Designed by 24k Studio
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default ComingSoon02;
