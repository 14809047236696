import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/scss/style.scss";

import ComingSoon02 from "./pages/comingSoon02";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" component={ComingSoon02} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;